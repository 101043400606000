import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import utility from '../../commons/utility.js'

export class CustomAccordion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isShrink: true
        }
    }

    render() {
        return (
            <>
                <div className={this.props.head.class + ' cursorPointer'} onClick={() => this.setState({ isShrink: !this.state.isShrink })}>
                    <Icon name={this.state.isShrink ? 'chevron right' : 'chevron down'} />
                    {this.props.head.title}
                </div>
                {!this.state.isShrink &&
                    <>
                        {this.props.children}
                    </>
                }
            </>
        )
    }
}

export default CustomAccordion
