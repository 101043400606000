import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'

export class RenderTable extends Component {
   render() {
      let { columns, rows, tableProps } = this.props
      return (
         <>
            <Table {...tableProps}>
               <Table.Header>
                  <Table.Row>
                     {columns.map(column =>
                        <Table.HeaderCell key={column.key}>{column.title}</Table.HeaderCell>
                     )}
                  </Table.Row>
               </Table.Header>

               <Table.Body>
                  {rows.map((row, key) => {
                     return (
                        <Table.Row key={key}>
                           {columns.map(column => {
                              return (
                                 <Table.Cell textAlign={column.textAlign} key={column.key}>{row[column.key]}</Table.Cell>
                              )
                           })}
                        </Table.Row>
                     )
                  })}
               </Table.Body>
            </Table>
         </>
      )
   }
}

export default RenderTable
