import React, { Component } from "react";
import { Grid, Input, Accordion, Dropdown, Checkbox, Select, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message } from "semantic-ui-react";
import PageHeader from "../PageHeader.jsx";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from "../../commons/agent.js";
import utility from "../../commons/utility.js";
import util from "./UserSignUp.js";
import DatePicker from "react-datepicker";
import DisplayMessage from "../../commons/displayMessage.jsx";
import Footer from "../footer.jsx";
import MenuHeader from "../navbar.jsx";
import { TermOfServiceModal } from "./TermOfServiceModal.jsx";
import { PrivacyPolicyModal } from "./PrivacyPolicyModal.jsx";
import UserDetails from "../../userInfo/UserDetails.js";

export class UserSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageType: "",
      messageHeader: "",
      message: [],
      isTermOfServiceChecked: false,
      isPrivacyPolicyChecked: false,
      termOfServiceIsOpen: false,
      privacyPolicyIsOpen: false,
      organizationUser: {
        organizationBpid: "",
        userId: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
        email: "",
        isAdmin: null,
        password: null,
        userIdError: "",
      },
      organizationUsers: [],

      organizationUsersError: "",
      error: {},

      organization: "",
    };
  }

  componentDidMount() {
    this.onLoad();
    util.initialize(this, this.props.t);
  }

  onLoad = () => {
    this.setState({ organization: this.props.fetchOrganizationList().filter((org) => org.organizationBpid == this.props.match.params.bpid)[0] });

    if (this.props?.updateMode) {
      let oldUser = this.props.location.state;
      this.setState({
        organizationUsers: [
          {
            organizationBpid: "",
            userId: oldUser.userId || "",
            firstName: oldUser.firstName || "",
            lastName: oldUser.lastName || "",
            contactNumber: oldUser.contactNumber || "",
            email: oldUser.email || "",
            isAdmin: oldUser?.isAdmin || null,
            password: null,
            userIdError: "",
          },
        ],
      });
    } else {
      this.addOrganizationUser();
    }
  };

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleRadioChange = (name, value) => {
    this.setState({ [name]: value });
  };
  handleDropdownChange = (name, value) => {
    this.setState({ [name]: value });
  };
  handleManufacturerIdTextChange = (e, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].organizationBpid = e.target.value;
    this.setState({ organizationUsers: [...organizationUsers] });
  };
  handleUsersIdTextChange = (e, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].userId = e.target.value;
    this.setState({ organizationUsers: [...organizationUsers] });
  };
  handleFirstNameTextChange = (e, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].firstName = e.target.value;
    this.setState({ organizationUsers: [...organizationUsers] });
  };
  handleLastNameTextChange = (e, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].lastName = e.target.value;
    this.setState({ organizationUsers: [...organizationUsers] });
  };
  handleContactNumberTextChange = (e, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].contactNumber = e.target.value;
    this.setState({ organizationUsers: [...organizationUsers] });
  };
  handleEmailTextChange = (e, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].email = e.target.value;
    this.setState({ organizationUsers: [...organizationUsers] });
  };
  handlePasswordTextChange = (e, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].password = e.target.value;
    this.setState({ organizationUsers: [...organizationUsers] });
  };
  handleConfirmPasswordTextChange = (e, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].confirmPassword = e.target.value;
    this.setState({ organizationUsers: [...organizationUsers] });
  };

  handleRoleChange = (val, key) => {
    let organizationUsers = [...this.state.organizationUsers];
    organizationUsers[key].isAdmin = val;
    this.setState({ organizationUsers: [...organizationUsers] });
  };

  addOrganizationUser = () => {
    this.setState({ organizationUsers: [...this.state.organizationUsers, { ...this.state.organizationUser }] });
  };

  render() {
    const { t, updateMode } = this.props;
    const oldUserDetails = this.props.location.state;
    const orgCategory = this.props.fetchOrganizationCategory(utility.fetchUserBpid());
    const regWho = UserDetails.platformadmin || orgCategory === "Improvement Service" ? "registerSuperAgent" : "registerAgent";

    return (
      <>
        {updateMode ? (
          <PageHeader customPath={`/dashboard/${oldUserDetails?.isAdmin ? "editSuperAgent" : "editAgent"}`} />
        ) : (
          <PageHeader customPath={`/dashboard/${UserDetails?.platformadmin || orgCategory === "Improvement Service" ? "registerSuperAgent" : "registerAgent"}`} />
        )}
        <Segment basic className="pt-0 px-1 my-0">
          <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
          <Grid columns="equal" centered>
            <Grid.Column computer={9} tablet={16} mobile={16}>
              {this.state.organizationUsers?.map((item, key) => (
                <Segment className="noShadow">
                  <Form size="small">
                    <Form.Group widths="equal">
                      <Form.Field width={2}>
                        <Icon name="user" size="big" circular className="mt-5px colPrimary" />
                      </Form.Field>
                      <Form.Field width={13} >
                        {/* <Form.Input fluid value={item.organizationBpid} onChange={(e) => this.handleManufacturerIdTextChange(e, key)} fluid label={t('partnerId')} placeholder={t('partnerId')} onBlur={() => util.validateOrganizationBpid(key)} required />
                                                {item.organizationBpidError && <div className='errorText'>{item.organizationBpidError}</div>} */}
                        <Form.Input style={{ width: "112%"}} label={t("organisationName")} value={this.state.organization.organizationName} readOnly className="readOnly" required />
                        {/* {this.state.organizationName && <div className='colPrimary textNormal'>{this.state.organizationName}</div>} */}
                      </Form.Field>
                      <Form.Field style={{ width: "91%", marginLeft: '30px'}}>
                        <Form.Input
                          readOnly={updateMode}
                          value={item?.userId}
                          onChange={(e) => this.handleUsersIdTextChange(e, key)}
                          label={t("userId")}
                          placeholder={t("userId")}
                          onBlur={() => util.validateUserId(key)}
                          required
                        />
                        {!updateMode && item.userIdError && <div className="errorText">{item.userIdError}</div>}
                      </Form.Field>

                    </Form.Group>
                    <div style={{ paddingLeft: '10%' }}>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Form.Input
                            value={item.firstName}
                            onChange={(e) => this.handleFirstNameTextChange(e, key)}
                            fluid
                            label={t("firstName")}
                            placeholder={t("firstName")}
                            onBlur={() => util.validateFirstName(key)}
                            required
                          />
                          {item.firstNameError && <div className="errorText">{item.firstNameError}</div>}
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            value={item.lastName}
                            onChange={(e) => this.handleLastNameTextChange(e, key)}
                            fluid
                            label={t("lastName")}
                            placeholder={t("lastName")}
                            onBlur={() => util.validateLastName(key)}
                            required
                          />
                          {item.lastNameError && <div className="errorText">{item.lastNameError}</div>}
                        </Form.Field>
                      </Form.Group>
                    </div>
                    <div style={{ paddingLeft: '10%' }}>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Form.Input
                            value={item.contactNumber}
                            onChange={(e) => this.handleContactNumberTextChange(e, key)}
                            fluid
                            label={t("contactNumber")}
                            placeholder={t("contactNumber")}
                            onBlur={() => util.validateContactNumber(key)}
                            required
                          />
                          {item.contactNumberError && <div className="errorText">{item.contactNumberError}</div>}
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            value={item.email}
                            onChange={(e) => this.handleEmailTextChange(e, key)}
                            fluid
                            label={t("email")}
                            placeholder={t("email")}
                            onBlur={() => util.validateEmail(key)}
                            required
                          />
                          {item.emailError && <div className="errorText">{item.emailError}</div>}
                        </Form.Field>
                      </Form.Group>
                    </div>

                    {/* {updateMode && orgCategory === "Improvement Service" &&
                      <Form.Group widths="equal" >
                        <Form.Field>
                          <label style={{ marginTop: '4px' }}>Change user role to {oldUserDetails?.isAdmin ? "Agent" : "Super agent"}</label>
                          <Radio 
                            checked={item.isAdmin}
                            onChange={(e, data) => this.handleRoleChange(data.checked, key)}
                          />
                        </Form.Field>
                      </Form.Group>
                    } */}

                    {updateMode && orgCategory === "Improvement Service" &&
                      <div style={{ paddingLeft: '10%' }}>
                        <label style={{ marginTop: '4px', fontWeight: 'bold' }}>{t("changeUserRole")}</label>
                        <Form.Field>
                          <Radio
                            label={t("agent")}
                            value={false}
                            checked={item.isAdmin ? false : true}
                            onChange={(e, { value }) => this.handleRoleChange(value, key)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            label={t("superAgent")}
                            value={true}
                            checked={item.isAdmin ? true : false}
                            onChange={(e, { value }) => this.handleRoleChange(value, key)}
                          />
                        </Form.Field>
                      </div>
                    }

                    {/* <Form.Group widths='equal'>
                                            <Form.Field>
                                                <Form.Input type='password' value={item.password} onChange={(e) => this.handlePasswordTextChange(e, key)} fluid label={t('password')} placeholder={t('password')} onBlur={() => util.validatePassword(key)} required />
                                                {item.passwordError && <div className='errorText'>{item.passwordError}</div>}
                                            </Form.Field>
                                            <Form.Field>
                                                <Form.Input type='password' value={item.confirmPassword} onChange={(e) => this.handleConfirmPasswordTextChange(e, key)} fluid label={t('confirmPassword')} placeholder={t('confirmPassword')} onBlur={() => util.validateConfirmPassword(key)} required />
                                                {item.confirmPasswordError && <div className='errorText'>{item.confirmPasswordError}</div>}
                                            </Form.Field>
                                        </Form.Group> */}
                  </Form>
                </Segment>
              ))}
              {this.state.organizationUsersError && (
                <Message color="red">
                  <p>{this.state.organizationUsersError}</p>{" "}
                </Message>
              )}

              <Grid>
                <Grid.Column computer={16} textAlign="right">
                  {updateMode ? (
                    <>
                      <Button className="customBtn1Secondary" onClick={() => this.props.history.goBack()}>
                        {t("cancel")}
                      </Button>
                      <Button className="customBtn1Primary mr-0" onClick={() => util.update(regWho)}>
                        {t("save")}
                      </Button>
                    </>
                  ) : (
                    <Button className="customBtn1Primary mr-0" onClick={() => util.publish(regWho)}>
                      {t("register")}
                    </Button>
                  )}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    );
  }
}

export default withTranslation()(withRouter(UserSignUp));
