import React from "react";
import ReactDOM from "react-dom";
import { Grid } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import "@fontsource/roboto";
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "./main.css";
import "semantic-ui-css/semantic.min.css";
import 'antd/dist/antd.css'

import './i18n';
import Encrypt from "./components/Login/helpers/Encrypt.jsx";
import LandingPage from "./components/landingPage.jsx";
import LoginHome from "./components/Login/loginHome.jsx";
import OrganizationSignUp from "./components/signUp/OrganizationSignUp.jsx";
import UserSignUp from "./components/signUp/UserSignUp.jsx";
import OrganizationRequest from "./components/signUp/OrganizationRequest.jsx";
import UserRequest from "./components/signUp/UserRequest.jsx";
import ForgotPassword from './components/passwordManagement/ForgotPassword.jsx';
import DcnUser from "./components/dcnUser.jsx";
import ForgotUsername from "./components/passwordManagement/ForgotUsername.jsx";
import ResetPassword from "./components/passwordManagement/ResetPassword.jsx";
import Help from "./components/helpdesk/Help.jsx";
import HelpdeskQuery from "./components/helpdesk/HelpdeskQuery.jsx";
import TermsAndConditions from "./components/terms/TermsAndConditions.jsx";
import CookiePolicy from "./components/terms/CookiePolicy.jsx";
import AccessibilityStatement from "./components/terms/AccessibilityStatement.jsx";
import PrivacyPolicy from "./components/terms/PrivacyPolicy.jsx";

const rootNode = document.getElementById('root');

function App() {
    return (
        <Router>
            <div className="App">
                <Grid>
                    <Grid.Row className='py-0'>
                        <Grid.Column width={16} className="p-0 body">
                            <Switch>
                                <Route exact path="/" component={LandingPage} />
                                <Route path="/dashboard" render={() => <DcnUser />} />
                                {/* <Route path="/dcnUser" render={()=><DcnUser />} /> */}
                                <Route path="/login" render={() => <LoginHome />} />
                                <Route path="/encryption" exact component={Encrypt} />
                                <Route path="/forgotPassword" exact component={ForgotPassword} />
                                <Route path="/forgotUsername" exact component={ForgotUsername} />
                                <Route path="/resetPassword" exact component={ResetPassword} />
                                <Route path="/help" exact component={Help} />
                                <Route path="/helpdeskQuery" render={() => <HelpdeskQuery />} />
                                <Route path="/termsAndConditions" render={() => <TermsAndConditions />} />
                                <Route path="/privacyPolicy" render={() => <PrivacyPolicy />} />
                                <Route path="/cookiePolicy" render={() => <CookiePolicy />} />
                                <Route path="/accessibilityStatement" render={() => <AccessibilityStatement />} />
                                <Route path="*" exact render={() => <Redirect to='/' />} />
                            </Switch>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>                
            </div>
        </Router>

    );
}

export default App;

ReactDOM.render(<App />, rootNode);
