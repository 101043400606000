import moment from "moment";
import ajax from "../../commons/agent.js";
import utility from "../../commons/utility.js";

let context;
let t;

const initialize = (self, translate) => {
  context = self;
  t = translate;
};
const populateVo = () => {
  let bpId = context.state.organization.organizationBpid;
  let Vo = context.state.organizationUsers.map((item) => ({
    organizationBpid: bpId,
    userId: item.userId.trim(),
    firstName: item.firstName.trim(),
    lastName: item.lastName.trim(),
    contactNumber: item.contactNumber.trim(),
    email: item.email.trim(),
    isAdmin: item.isAdmin 
    //  password: item.password.trim(),
  }));
  return Vo;
};

const validateOrganizationBpid = (key) => {
  let error = "";
  context.setState({ organizationName: "" });
  const value = context.state.organizationUsers[key].manufacturerId;
  if (!value) error = t("requiredValidationRule", { what: t("partnerId") });
  else if (value.length < 3) error = "Length should be more than 3 characters";
  else if (value.length > 50) error = "Length should be less than 50 characters";
  else {
    ajax.OrganizationMaster.findOrganizationNameByBPID(value).end((err, response) => {
      utility.handleErrors(err);
      if (!err && response) {
        if (response.text) {
          context.setState({ organizationName: response.text });
        } else {
          let temp = [...context.state.organizationUsers];
          temp[key].manufacturerIdError = "Organisation does not exist";
          context.setState({ organizationUsers: temp, organizationName: "" });
        }
      }
    });
  }

  let temp = [...context.state.organizationUsers];
  temp[key].manufacturerIdError = error;
  context.setState({ organizationUsers: temp });
  return error;
};
const validateUserId = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].userId;
  if (!value) error = t("requiredValidationRule", { what: t("userId") });
  else if (!/^[a-zA-Z0-9][a-zA-Z0-9_.-]+$/.test(value)) error = "Username should not include (@ £ $ % ^ & \" ' ! ~ #)";
  else if (value.length < 5) error = "Length should be more than 5 characters";
  else if (value.length > 50) error = "Length should be less than 50 characters";

  if (error === "") {
    ajax.OrganizationMaster.findUserAlreadyExists(value).end((err, response) => {
      utility.handleErrors(err);
      if (!err && response) {
        if (response.body) {
          let temp = [...context.state.organizationUsers];
          temp[key].userIdError = "User ID already has been taken";
          context.setState({ organizationUsers: temp });
        }
      }
    });
  }
  let temp = [...context.state.organizationUsers];
  temp[key].userIdError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

const validateFirstName = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].firstName;
  if (!value) error = t("requiredValidationRule", { what: t("firstName") });

  let temp = [...context.state.organizationUsers];
  temp[key].firstNameError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

const validateLastName = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].lastName;
  if (!value) error = t("requiredValidationRule", { what: t("lastName") });

  let temp = [...context.state.organizationUsers];
  temp[key].lastNameError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

const validateContactNumber = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].contactNumber;
  if (!value) error = t("requiredValidationRule", { what: t("contactNumber") });
  else if (value.search(/^[0-9]*$/) < 0) error = "Must contain only numbers 0-9";

  let temp = [...context.state.organizationUsers];
  temp[key].contactNumberError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

const validateEmail = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].email;
  if (!value) error = t("requiredValidationRule", { what: t("email") });
  if (value && !utility.isEmailValid(value)) error = "Enter a valid email. eg: example@mail.com";

  let temp = [...context.state.organizationUsers];
  temp[key].emailError = error;
  context.setState({ organizationUsers: temp });
  return error;
};


// const validatePassword = (key) => {
//     let error = ''
//     const value = context.state.organizationUsers[key].password
//     if (!value) error = 'Password is required'
// else if (value.length < 12) error = 'Password must be at least 12 characters'
// else if (value.length > 29) error = 'Password must be less than 30 characters'
// else if (value.search(/[a-z]/) < 0) error = 'Password should have at least one lower case (a-z)'
// else if (value.search(/[0-9]/) < 0) error = 'Password should have at least one number (0-9)'
// else if (!((/[$&\(\)*,@\[\]^_{}~£]/).test(value))) error = 'Password should have at least one special character out of these acceptable characters ($ & ( ) * , @ [ ] ^ _ { } ~ £ )'
// else if (((/[!"%+]/).test(value))) error = 'Password should not include (! " % or +)'
// else if ((/(\r\n|\n|\r|\s)+/).test(value)) error = 'Password should not contain spaces or new line characters'

//     let temp = [...context.state.organizationUsers]
//     temp[key].passwordError = error
//     context.setState({ organizationUsers: temp })
//     return error
// }

// const validateConfirmPassword = (key) => {
//     let error = ''
//     const value = context.state.organizationUsers[key].confirmPassword
//     const passwordValue = context.state.organizationUsers[key].password
//     if (!value) error = t('requiredValidationRule', { what: t('password') })
//     if (passwordValue && value !== passwordValue) error = 'Password does not match'

//     let temp = [...context.state.organizationUsers]
//     temp[key].confirmPasswordError = error
//     context.setState({ organizationUsers: temp })
//     return error
// }

// const validateTermOfService = () => {
//     let error = ''
//     const value = context.state.isTermOfServiceChecked
//     if (!value) error = t('youMustAgree', { what: t('termOfService') })

//     context.setState(prevState => ({ error: ({ ...prevState.error, termOfService: error }) }))
//     return error
// }

// const validatePrivacyPolicy = () => {
//     let error = ''
//     const value = context.state.isPrivacyPolicyChecked
//     if (!value) error = t('youMustAgree', { what: t('privacyPolicy') })

//     context.setState(prevState => ({ error: ({ ...prevState.error, privacyPolicy: error }) }))
//     return error
// }

const validateUser = () => {
  let usersArray = [...context.state.organizationUsers];
  let eArray = [];
  usersArray.map((item, key) => {
    //  let e0 = validateOrganizationBpid(key);
    let e1 = validateUserId(key);
    let e2 = validateFirstName(key);
    let e3 = validateLastName(key);
    let e4 = validateContactNumber(key);
    let e5 = validateEmail(key);
    // let e6 = validatePassword(key)
    // let e7 = validateConfirmPassword(key)

    eArray.push(
      !!(
        //   e0 ||
        (e1 || e2 || e3 || e4 || e5)
        // || e6 || e7
      )
    );
  });
  return eArray.includes(true);
};

const validateAll = () => {
  // validateTermOfService()
  // validatePrivacyPolicy()

  return validateUser();
};

const publish = (val) => {
  let Vo = populateVo();
  //   console.log(Vo);
  if (validateAll()) {
    utility.loadDisplayMessage(context, "error", "Invalid Input", ["Fill the required form fields with valid data."]);
  } else {
    context.setState({ isSubmitting: true });
    if (val === "registerSuperAgent") {
      ajax.OrganizationMaster.createSuperagent(Vo[0]).end((err, response) => {
        context.setState({ isSubmitting: false });
        utility.handleErrors(err);
        utility.loadingModalLoader(context, "Please wait. redirecting...");
        if (!err && response) {
          //  context.props.history.push({
          //    pathname: "/dashboard/userrequest",
          //    state: {
          //      ...response.body,
          //    },
          //  });
          context.props.history.push(`/dashboard/organisations/${context.state.organization.organizationBpid}`);
        } else {
          utility.unLoadingModalLoader(context);
          utility.loadDisplayMessage(context, "error", "Failed.", ["Problem initiating Workflow.", "Please contact system administrator."]);
        }
      });
    } else {
      ajax.OrganizationMaster.createAgent(Vo[0]).end((err, response) => {
        context.setState({ isSubmitting: false });
        utility.handleErrors(err);
        utility.loadingModalLoader(context, "Please wait. redirecting...");
        if (!err && response) {
          //  context.props.history.push({
          //    pathname: "/dashboard/userrequest",
          //    state: {
          //      ...response.body,
          //    },
          //  });
          context.props.history.push(`/dashboard/organisations/${context.state.organization.organizationBpid}`);
        } else {
          utility.unLoadingModalLoader(context);
          utility.loadDisplayMessage(context, "error", "Failed.", ["Problem initiating Workflow.", "Please contact system administrator."]);
        }
      });
    }
  }
};
const update = (val) => {
  let Vo = populateVo();
  // console.log(Vo);
  if (validateAll()) {
    utility.loadDisplayMessage(context, "error", "Invalid Input", ["Fill the required form fields with valid data."]);
  } else {
    context.setState({ isSubmitting: true });
    ajax.OrganizationMaster.updateUser(Vo[0]).end((err, response) => {
      context.setState({ isSubmitting: false });
      utility.handleErrors(err);
      utility.loadingModalLoader(context, "Please wait. redirecting...");
      if (!err && response) {
        //  context.props.history.push({
        //    pathname: "/dashboard/userrequest",
        //    state: {
        //      ...response.body,
        //    },
        //  });
        context.props.history.push(`/dashboard/organisations/${context.state.organization.organizationBpid}`);
      } else {
        utility.unLoadingModalLoader(context);
        utility.loadDisplayMessage(context, "error", "Failed.", ["Problem initiating Workflow.", "Please contact system administrator."]);
      }
    });
  }
};
export default {
  initialize,
  publish,
  update,
  validateOrganizationBpid,
  validateUserId,
  validateFirstName,
  validateLastName,
  validateContactNumber,
  validateEmail,
  //  validatePassword,
  //validateConfirmPassword,
  validateUserId,
  validateFirstName,
  validateLastName,
  validateContactNumber,
  validateEmail,
  // validatePassword,
  //validateConfirmPassword,
};
