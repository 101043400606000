import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Header, Segment, Grid, Divider, Label, Icon, Button } from 'semantic-ui-react';
import DisplayMessage from '../../commons/displayMessage.jsx'
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js'
import Footer from '../footer.jsx';
import MenuHeader from '../navbar.jsx';
import PageHeader from '../PageHeader.jsx';


class UserRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            messageType: '', messageHeader: '', message: [],

            isFetching: false,
            userDetails: null,
        });
    }
    componentDidMount() {
        this.onLoad();
    }

    onLoad = () => {
        this.setState({
            userDetails: this.props.location.state
        })
    }

    render() {
        const { userDetails } = this.state

        var bgColors = {
            "DarkBlue": "#3A5F8F", "White": "#f5f5f5", "LightBlue": "#059EC7", "Green": "#00A651", "Orange": "#FF6633",
            "OrangeProgress": "#FF9933", "GreenProgress": "#3CB878", "Black": "#2A2A2A", "Grey": "#DDDDDD", "LightGrey": "#F2FBFE",
            "LightBlack": "#4E4E4E", "GreyishWhite": "#A8A8A8", "DarkGrey": "#707070", "SearchColor": "#A2A2A2", "TableHeader": "#919191"
        };
        var segmentStyle = { marginLeft: '3%', marginRight: '3%', marginTop: '1%', borderColor: bgColors.Grey }
        var labelStyle = { fontSize: '0.971em', color: bgColors.DarkGrey, fontFamily: 'Verdana' }
        var contentStyle = { fontSize: '0.971em', color: bgColors.Black, fontFamily: 'Verdana' }
        var buttonstyle = { backgroundColor: bgColors.LightBlue, color: bgColors.White, fontFamily: 'Verdana', letterSpacing: '1px', fontWeight: 5, fontSize: '0.867em' }
        var redFont = { fontSize: '0.971em', color: bgColors.Orange, fontFamily: 'Verdana' }
        var greenFont = { fontSize: '0.971em', color: bgColors.Green, fontFamily: 'Verdana' }
        var floatright = { float: 'right' }

        var baseURL = "https://" + window.location.host + "/";
        return (
            <>
                <PageHeader />
                <Segment style={segmentStyle} loading={this.state.isFetching}>
                    <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
                    {userDetails && <>
                        <Header as='h3' color='blue'>Request submitted successfully</Header>
                        <Header as='h4'>User Details:</Header>
                        <Divider />
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >User ID: </label>
                                        <span style={contentStyle}>{userDetails.userId} </span> </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Name: </label>
                                        <span style={contentStyle}> {userDetails.firstName + " " + userDetails.lastName} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Workflow Status: </label>
                                        <span style={userDetails.status === 'Pending' ? redFont : greenFont}>{userDetails.status} </span></div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Phone: </label>
                                        <span style={contentStyle}>{userDetails.contactNumber} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Email: </label>
                                        <span style={contentStyle}>{userDetails.email} </span></div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Divider />
                        <Button style={buttonstyle} id="printButton" onClick={() => window.print()}>Print</Button>
                    </>}
                </Segment>
            </>
        )
    }
}
export default withRouter(UserRequest);
