import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Header, Segment, Grid, Divider, Label, Icon, Button } from 'semantic-ui-react';
import DisplayMessage from '../../commons/displayMessage.jsx'
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js'
import Footer from '../footer.jsx';
import MenuHeader from '../navbar.jsx';
import PageHeader from '../PageHeader.jsx';


class OrganizationRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            messageType: '', messageHeader: '', message: [],

            isFetching: false,
            organizationDetails: null,
        });
    }
    componentDidMount() {
        this.onLoad();
    }

    onLoad = () => {
        this.setState({
            organizationDetails: this.props.location.state
        })
    }

    render() {
        const { organizationDetails } = this.state

        var bgColors = {
            "DarkBlue": "#3A5F8F", "White": "#f5f5f5", "LightBlue": "#059EC7", "Green": "#00A651", "Orange": "#FF6633",
            "OrangeProgress": "#FF9933", "GreenProgress": "#3CB878", "Black": "#2A2A2A", "Grey": "#DDDDDD", "LightGrey": "#F2FBFE",
            "LightBlack": "#4E4E4E", "GreyishWhite": "#A8A8A8", "DarkGrey": "#707070", "SearchColor": "#A2A2A2", "TableHeader": "#919191"
        };
        var segmentStyle = { marginLeft: '3%', marginRight: '3%', marginTop: '1%', borderColor: bgColors.Grey }
        var labelStyle = { fontSize: '0.971em', color: bgColors.DarkGrey, fontFamily: 'Verdana' }
        var contentStyle = { fontSize: '0.971em', color: bgColors.Black, fontFamily: 'Verdana' }
        var buttonstyle = { backgroundColor: bgColors.LightBlue, color: bgColors.White, fontFamily: 'Verdana', letterSpacing: '1px', fontWeight: 5, fontSize: '0.867em' }
        var redFont = { fontSize: '0.971em', color: bgColors.Orange, fontFamily: 'Verdana' }
        var greenFont = { fontSize: '0.971em', color: bgColors.Green, fontFamily: 'Verdana' }
        var floatright = { float: 'right' }

        var baseURL = "https://" + window.location.host + "/";
        return (
            <>
                <PageHeader />
                <Segment style={segmentStyle} loading={this.state.isFetching}>
                    <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
                    {organizationDetails && <>
                        <Header as='h3' color='blue'>Request submitted successfully</Header>
                        <Header as='h4'>Organisation Details</Header>
                        <Divider />

                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Request ID: </label>
                                        <span style={contentStyle}>{organizationDetails.organizationId} </span></div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Organisation Name: </label>
                                        <span style={contentStyle}>{organizationDetails.organizationName} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Workflow: </label>
                                        <span style={organizationDetails.status === 'Pending' ? redFont : greenFont}>
                                            {organizationDetails.status} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Website: </label>
                                        <span style={contentStyle}>{organizationDetails.website} </span></div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Organisation Category: </label>
                                        <span style={contentStyle}>{organizationDetails.organizationCategory} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Corporate Email: </label>
                                        <span style={contentStyle}>{organizationDetails.emailId} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Corporate Phone Number: </label>
                                        <span style={contentStyle}>{organizationDetails.contactNumber} </span></div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Address: </label>
                                        <span style={contentStyle}>{organizationDetails.addressLine} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Country: </label>
                                        <span style={contentStyle}>{organizationDetails.country} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >State: </label>
                                        <span style={contentStyle}>{organizationDetails.stateProvinceRegion} </span></div>
                                </Grid.Column>

                            </Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >City: </label>
                                        <span style={contentStyle}>{organizationDetails.city} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Postal Code: </label>
                                        <span style={contentStyle}>{organizationDetails.zipCode} </span></div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Remark: </label>
                                        <span style={contentStyle}>{organizationDetails.remark} </span></div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Header as='h4'>Profile:</Header>
                        <Divider />
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <span style={contentStyle}>{organizationDetails.organizationProfile} </span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Header as='h4'>Super Agent(s) Details:</Header>
                        <Divider />
                        {
                            organizationDetails.organizationUsersVo.map(function (items, index) {
                                return (
                                    <Grid>
                                        <Grid.Row columns={3}>
                                            <Grid.Column>
                                                <div style={labelStyle}><label className="required" >User ID: </label>
                                                    <span style={contentStyle}>{items.userId}</span> </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <div style={labelStyle}><label className="required" >Name: </label>
                                                    <span style={contentStyle}>{items.firstName + " " + items.lastName}</span> </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <div style={labelStyle}><label className="required" >Email: </label>
                                                    <span style={contentStyle}>{items.email}</span> </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={3}>
                                            <Grid.Column>
                                                <div style={labelStyle}><label className="required" >Status: </label>
                                                    <span style={items.status === 'Pending' ? redFont : greenFont}>
                                                        {items.status}</span> </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <div style={labelStyle}><label className="required" >Phone Number: </label>
                                                    <span style={contentStyle}>{items.contactNumber}</span> </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                )
                            })}
                        <Header as='h4'>Work flow Status:</Header>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <div style={labelStyle}><label className="required" >Your request is awaiting approval. </label></div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider />
                        <Button style={buttonstyle} id="printButton" onClick={() => window.print()}>Print</Button>
                    </>}
                </Segment>
            </>

        )
    }
}
export default withRouter(OrganizationRequest);
