import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import { Header, Modal, Segment, Grid, Button } from 'semantic-ui-react';


export class PrivacyPolicyModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }


    render() {
        const { t } = this.props.context.props

        return (
            <>
                <Modal
                    open={this.props.isOpen}
                    centered={false}
                    onClose={() => this.props.onClose()}
                    dimmer='inverted'
                    size='large'
                >
                    <Modal.Content className='py-5px'>
                        <Segment basic className='p-0'>
                            <Header as='h2' className='my-5px'>{t('termOfService')}</Header>

                            <Header>1. Introduction.</Header>
                            <p align="justify">
                                These Website Standard Terms and Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage,
                                shall govern your use of this website, including all pages within this website (collectively referred to herein below
                                as this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website,
                                you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any
                                bjection to any of these Website Standard Terms And Conditions. This Website is not for use by any minors
                                (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor.
                            </p>
                            <Header>2. Restrictions.</Header>
                            <ol>
                                <li>Publishing any Website material in any media;</li>
                                <li>Selling, sublicensing and/or otherwise commercializing any Website material;</li>
                                <li>Using this Website in any way that is, or may be, damaging to this Website;</li>
                                <li>Using this Website in any way that impacts user access to this Website;</li>
                                <li>Using this Website contrary to applicable laws and regulations, or in a way that causes,
                                    or may cause, harm to the Website, or to any person or business entity;</li>
                                <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in
                                    relation to this Website, or while using this Website;</li>
                            </ol>
                            <Header>3. Your Content.</Header>
                            <p align="justify">
                                Your Content must be your own and must not be infringing on any third party’s rights.
                                TCS and CCAMP reserves the right to remove any of Your Content from this Website at any time,
                                and for any reason, without notice.
                            </p>
                            <Header>4. No warranties.</Header>
                            <p align="justify">
                                This Website is provided “as is,” with all faults, and TCS and CCAMP makes no express or
                                implied representations or warranties, of any kind related to this Website or the materials
                                contained on this Website. Additionally, nothing contained on this Website shall be construed
                                as providing consult or advice to you.
                            </p>
                            <Header>5. Limitation of liability.</Header>
                            <p align="justify">
                                In no event shall TCS, CCAMP, nor any of its officers, directors and employees, be liable to
                                you for anything arising out of or in any way connected with your use of this Website, whether
                                such liability is under contract, tort or otherwise, and TCS, CCAMP, including its officers,
                                directors and employees shall not be liable for any indirect, consequential or special liability
                                arising out of or in any way related to your use of this Website.
                            </p>
                            <Header>6. Indemnification</Header>
                            <p align="justify">
                                You hereby indemnify to the fullest extent TCS and CCAMP from and against any and all liabilities,
                                costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees)
                                arising out of or in any way related to your breach of any of the provisions of these Terms.
                            </p>
                            <Header>7. Severability</Header>
                            <p align="justify">
                                If any provision of these Terms is found to be unenforceable or invalid under any applicable law,
                                such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole,
                                and such provisions shall be deleted without affecting the remaining provisions herein.
                            </p>
                            <Header>8. Variation of Terms.</Header>
                            <p align="justify">
                                TCS and CCAMP are permitted to revise these Terms at any time as it sees fit, and by using this
                                Website you are expected to review such Terms on a regular basis to ensure you understand all
                                terms and conditions governing use of this Website.
                            </p>
                            <Header>9. Assignment.</Header>
                            <p align="justify">
                                CCAMP shall be permitted to assign, transfer, and subcontract its rights and/or obligations
                                under these Terms without any notification or consent required. However, you shall not be
                                permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                            </p>
                            <Header>10. Entire Agreement.</Header>
                            <p align="justify">
                                These Terms, including any legal notices and disclaimers contained on this Website, constitute the
                                entire agreement between CCAMP and you in relation to your use of this Website, and supersede all
                                prior agreements and understandings with respect to the same.
                            </p>
                            <Header>11. Governing Law & Jurisdiction.</Header>
                            <p align="justify">
                                These Terms will be governed by and construed in accordance with the laws of the State of Karnataka,
                                and you submit to the non-exclusive jurisdiction of the state and federal courts located in Karnataka
                                for the resolution of any disputes.
                            </p>
                            <Header>12. Privacy.</Header>
                            <p align="justify">
                                CCAMP and TCS are committed to protecting the privacy and security of your personal data. This privacy
                                notice describes how we collect and use personal data about you during and after your use of this
                                [Marketplace Platform] in accordance with the General Data Protection Regulation (GDPR).
                                It applies to all users of this [Marketplace Platform]. CCAMP is a “data controller”. This means
                                that we are responsible for deciding how we hold and use personal data about you.
                                We are required under data protection legislation to notify you of the information
                                contained in this privacy notice. This notice applies to current and former users.
                                [This notice does not form part of any contract of employment or other contract to provide services.]
                                We may update this notice at any time. <br />
                                It is important that you read this notice, together with any other privacy notice we may provide on
                                specific occasions when we are collecting or processing personal data about you, so that you are aware
                                of how and why we are using such information.
                            </p>
                            <p align="justify">
                                DATA PROTECTION PRINCIPLES<br />
                                We will comply with data protection law. This means that the personal data we hold about you must be:<br />
                                <ol>
                                    <li>Used lawfully, fairly and in a transparent way.</li>
                                    <li>Collected only for valid purposes that we have clearly explained to you and not used in any way that is incompatible with those purposes.</li>
                                    <li>Relevant to the purposes we have told you about and limited only to those purposes.</li>
                                    <li>Accurate and kept up to date.</li>
                                    <li>Kept only as long as necessary for the purposes we have told you about.</li>
                                    <li>Kept securely.</li>
                                </ol>
                            </p>
                            <p align="justify">
                                THE KIND OF INFORMATION WE HOLD ABOUT YOU<br />
                                Personal data means any information about an individual from which that person can
                                be identified. It does not include data where the identity has been removed
                                (aka anonymous data). There are “special categories” of more sensitive personal data,
                                which require a higher level of protection. We will collect, store, and use the
                                following categories of personal data about you: <br />
                                <ul>
                                    <li>Not Applicable</li>
                                </ul><br />
                                We may also collect, store and use the following “special categories” of more sensitive personal
                                data [update to reflect scope of processing; if not in scope add “not applicable”]:<br />
                                <ul>
                                    <li>Not Applicable</li>
                                </ul><br />
                                HOW IS YOUR PERSONAL DATA COLLECTED<br />
                                We collect personal data (email and phone number) about Organisation and Point of Contacts
                                through form filled on our portal.<br />
                                HOW WE WILL USE INFORMATION ABOUT YOU<br />
                                We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:<br />
                                <ol>
                                    <li>Where we need to execute the contract we have entered into with you.</li>
                                    <li>Where we need to comply with a legal obligation.</li>
                                    <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                                    <li>We may also use your personal data in the following situations, which are likely to be rare:<br />
                                        1. Where we need to protect your interests (or someone else’s interests).<br />
                                        2. Where it is needed in the public interest [or for official purposes].<br /></li>
                                </ol><br />
                                Situations in which we will use your personal data<br />
                                We need all the categories of information in the list above primarily to [allow us to perform our contract with you and to enable us to comply with legal obligations.
                                In some cases we may use your personal data to pursue legitimate interests of our own or those of third parties, provided your interests and fundamental rights do not override those interests.]
                                The situations in which we will process your personal data are listed below.<br />

                                <ul>
                                    <li>Administering the contract we have entered into with you.</li>
                                    <li>Making arrangements for the termination of contract and relationship.</li>
                                    <li>Dealing with legal disputes.</li>
                                    <li>To prevent fraud.</li>
                                    <li>To monitor your use of this [Marketplace Platform]</li>
                                    <li>To ensure network and information security, including preventing unauthorised access to our computer and electronic communications systems
                                        and preventing malicious software distribution.</li>
                                </ul><br />
                                Some of the above grounds for processing will overlap and there may be several grounds, which justify our use of your personal data.<br />
                                If you fail to provide personal data<br />
                                If you fail to provide certain information when requested, we may not be able to perform the contract we have entered into with you
                                (such as providing named access to the [Marketplace Platform]), or we may be prevented from complying with
                                our legal obligations (such as lawful disclosures).<br />

                                Change of purpose<br />

                                We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it
                                for another reason and that reason is compatible with the original purpose. If we need to use your personal data for an unrelated purpose,
                                we will notify you and we will explain the legal basis which allows us to do so. Please note that we may process your personal data without
                                your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.<br />

                                HOW WE USE PARTICULARLY SENSITIVE PERSONAL DATA<br />

                                Not Applicable<br />

                                DATA SHARING<br />
                                We may have to share your data with third parties, including third-party service providers and other entities in the group.
                                We require third parties to respect the security of your data and to treat it in accordance with the law.
                                We may transfer your personal data outside the EU/EEA. If we do, you can expect a similar degree of protection in respect of your personal data.<br />

                                Personal data sharing with third parties<br />

                                We will share your personal data with third parties where required by law, where it is necessary to administer the [working] relationship with you or
                                where we have another legitimate interest in doing so.<br />

                                Third parties processing your personal data<br />

                                ”Third parties” includes third-party service providers (including contractors and designated agents) and other entities within our group.
                                Security of your personal data, which is in custody of third-party service providers and other entities in the group All our third-party
                                service providers and other entities in the group are contractually bound to take appropriate security measures to protect your personal
                                data in line with our policies. We do not allow our third-party service providers to use your personal data for their own purposes.
                                We only permit them to process your personal data for specified purposes and in accordance with our instructions<br />

                                DATA SECURITY<br />
                                We have put in place measures to protect the security of your information. Third parties will only process your personal data on our
                                instructions and where they have agreed to treat the information confidentially and to keep it secure. <br />
                                We have put in place appropriate technical, Organisational and security measures to prevent your personal data from being accidentally lost,
                                used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents,
                                contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they
                                are subject to a duty of confidentiality. <br />We have put in place procedures to deal with any suspected data security breach and will notify you
                                and any applicable regulator of a suspected breach where we are legally required to do so.<br />

                                DATA RETENTION<br />

                                How long will you use my information for?<br />

                                We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying
                                any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature, and
                                sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process
                                your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.<br />
                                In some circumstances we may anonymize your personal data so that it can no longer be associated with you, in which case we may use such information without
                                further notice to you. Once you are no longer a user of the [Marketplace Platform] we will retain and securely destroy your personal data in accordance
                                with our data retention policy and applicable laws and regulations.<br />

                                RIGHTS OF ACCESS, CORRECTION, ERASURE, AND RESTRICTION<br />

                                Your duty to inform us of changes<br />

                                It is important that the personal data we hold about you is accurate and current. Please keep us
                                informed if your personal data changes during your relationship with us.<br />

                                Your rights in connection with personal data<br />

                                Under certain circumstances, by law you have the right to:<br />
                                <ul>
                                    <li>Request access to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                                    <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.</li>
                                    <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have exercised your right to object to processing (see below).</li>
                                    <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground. You also have the right to object where we are processing your personal data for direct marketing purposes.</li>
                                    <li>Request the restriction of processing of your personal data. This enables you to ask us to suspend the processing of personal data about you, for example if you want us to establish its accuracy or the reason for processing it.</li>
                                    <li>Request the transfer of your personal data to another party.</li>
                                </ul><br />
                                If you want to review, verify, correct or request erasure of your personal data, object to the processing of your personal data, or request that
                                we transfer a copy of your personal data to another party, please contact [add relevant contact] in writing
                                [OR visit [add url for automated option to exercise rights]]. <br />

                                No fee usually required<br />

                                You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request for access is clearly unfounded or excessive.
                                Alternatively, we may refuse to comply with the request in such circumstances.<br />

                                What we may need from you<br />

                                We may need to request specific information from you to help us confirm your identity and ensure your right to access the information (or to exercise any of your other rights).
                                This is another appropriate security measure to ensure that personal data is not disclosed to any person who has no right to receive it.<br />

                                RIGHT TO WITHDRAW CONSENT<br />

                                In the limited circumstances where you may have provided your consent to the collection,
                                processing and transfer of your personal data for a specific purpose, you have the right to withdraw
                                your consent for that specific processing at any time. To withdraw your consent,
                                please contact [add contact details]. Once we have received notification that you have withdrawn
                                your consent, we will no longer process your information for the purpose or purposes you
                                originally agreed to, unless we have another legitimate basis for doing so in law.<br />

                                CHANGES TO THIS PRIVACY NOTICE<br />
                                We reserve the right to update this privacy notice at any time, and we will provide
                                you with a new privacy notice when we make any substantial updates. We may also
                                notify you in other ways from time to time about the processing of your personal data.<br />

                                CONTACT<br />
                                If you have any questions about this privacy notice, please contact indx-support@ccamp.res.in<br />
                            </p>

                            <Grid className='mb-5px'>
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button className='customBtn1Secondary' onClick={() => this.props.onClose()}>{t('reject')}</Button>
                                    <Button className='customBtn1Primary' onClick={() => { this.props.context.setState({ isPrivacyPolicyChecked: true }); this.props.onClose() }}>{t('accept')}</Button>
                                </Grid.Column>
                            </Grid>

                        </Segment>
                    </Modal.Content>
                </Modal>
            </>


        )
    }
}

export default withTranslation()(withRouter(PrivacyPolicyModal))
