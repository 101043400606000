import React, { Component } from "react";
import { Grid, Icon, List, Image, Rating, Segment, Sidebar, Button, Label } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import ajax from '../../commons/agent.js'
import PageHeader from "../PageHeader.jsx";
import { withTranslation } from "react-i18next";
import utility from "../../commons/utility";
import MenuHeader from '../navbar.jsx';
import DisplayMessage from "../../commons/displayMessage.jsx";
import Footer from '../footer.jsx';

export class AccessibilityStatement extends Component {
    constructor(props) {
        super(props)

        this.state = {
            messageType: '', messageHeader: '', message: [],

        }
    }

    render() {
        const { t } = this.props
        return (
            <>
                <Grid>
                    <Grid.Column width={16} className="pb-0">
                        <MenuHeader />
                    </Grid.Column>
                </Grid>

                <Segment basic className='' style={{ minHeight: '52vh' }}>
                    <PageHeader />
                    <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />


                    <Grid centered style={{ minHeight: '68vh' }}>
                        <Grid.Column computer={12} tablet={14} mobile={16} className='mx-1'>
                            <Segment className='px-2' >
                                <div className='textBig colPrimary textBold'>Accessibility Statement</div>
                                <div className='colGrey60 textNormal'>Version 1.0,  November 2021</div><br/>
                                <div>
                                <p>dcn.scot recognises that there are different ways people access information on the web. We're aiming to design our site to be as accessible as possible to all, regardless of ability or disability.</p>
                                <p>This site has been designed to comply with the best practices of the Web Content Accessibility Guidelines (WCAG) 2.1.  All reasonable effort has been made to ensure that as much of the website as possible meets at least the 'AA' standard as set out in the <a href="http://www.w3.org/TR/WCAG/" target="_blank">WCAG 2.1 Guidelines</a>. You can visit the <a href="http://www.w3.org/TR/WCAG/" target="_blank">WCAG Accessibility Guidelines 2.1</a> site to familiarise yourself with all available guidelines.</p>
                                <p><b>Browsing the dcn.scot website</b></p>
                                <p><b>Reading</b></p>
                                <p>If you need to change the size of the text on any of the page, most internet browsers will allow you to do so. However, when changing your browser settings, keep this information in mind:
                                <ul>
                                <li>Bigger text means less information on the screen at any one time and more scrolling</li>
                                <li>You may need to click on the 'Refresh' or 'Reload' button for changes to take effect</li>
                                </ul>
                                </p>
                                <p>For more information on how to change the text size on your computer or browser screen you can visit the <a href="https://mcmw.abilitynet.org.uk/making-text-larger/" target="_blank">making text larger</a> section on the AbilityNet website. Some of the documents on this site are PDFs and require a PDF reader to view them. In most cases your web browser will suffice but if you are currently using an older version you may wish to download a dedicated PDF reader. Adobe Acrobat Reader is available as a free download from the <a href="https://get.adobe.com/reader/" target="_blank">Adobe website</a>.</p>
                                <p><b>Navigation</b></p>
                                <p>Your computer can be operated entirely from the keyboard. To learn how to navigate a web page using your keyboard, please visit the <a href="https://mcmw.abilitynet.org.uk/category/keyboard-shortcuts/" target="_blank">keyboard shortcuts</a> section of the AbilityNet website.</p>
                                <p><b>Design</b></p>
                                <p>Both text and background colours in your browser can be changed to better suit your needs. To learn how to adjust your colour settings please visit the <a href="https://mcmw.abilitynet.org.uk/changing-your-colours/" target="_blank">changing your colours</a> section of the AbilityNet website.</p>
                                <p><b>My Computer My Way</b></p>
                                <p>You can find more information and help about changing your browser settings on the <a href="https://mcmw.abilitynet.org.uk/" target="_blank">My Computer My Way</a> website.</p>
                                <p><b>Get in touch</b></p>
                                <p>If you experience difficulties using our website or if you wish to suggest improvements, please contact us.</p>
                                </div>
                            </Segment>

                        </Grid.Column>
                    </Grid>

                </Segment>

                <Footer />
            </>
        )
    }
}

export default withTranslation()(withRouter(AccessibilityStatement))

