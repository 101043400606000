import React, { Component } from "react";
import { Grid, Icon, List, Image, Rating, Segment, Sidebar, Button, Label, Table } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import ajax from '../../commons/agent.js'
import PageHeader from "../PageHeader.jsx";
import { withTranslation } from "react-i18next";
import utility from "../../commons/utility";
import MenuHeader from '../navbar.jsx';
import DisplayMessage from "../../commons/displayMessage.jsx";
import Footer from '../footer.jsx';

export class CookiePolicy extends Component {
    constructor(props) {
        super(props)

        this.state = {
            messageType: '', messageHeader: '', message: [],

        }
    }

    render() {
        const { t } = this.props
        return (
            <>
                <Grid>
                    <Grid.Column width={16} className="pb-0">
                        <MenuHeader />
                    </Grid.Column>
                </Grid>

                <Segment basic className='' style={{ minHeight: '52vh' }}>
                    <PageHeader />
                    <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />


                    <Grid centered style={{ minHeight: '68vh' }}>
                        <Grid.Column computer={12} tablet={14} mobile={16} className='mx-1'>
                            <Segment className='px-2' >
                                <div className='textBig colPrimary textBold'>Cookie Policy</div>
                                <div className='colGrey60 textNormal'>Version 1.0,  November 2021</div><br/>
                                <div>
                                <p>We use cookies when operating dcn.scot. A cookie is a small file consisting of letters and numbers that we temporarily store in your browser. Cookies help give you a better experience when using our website. They cannot be used to identify you personally.</p>
                                <p>We explain below the types of cookies we use and why we use them. By continuing to use dcn.scot or clicking on ‘Accept’, we shall assume you are happy with our use of cookies. If you don’t like our use of cookies, you can block them by activating the setting on your browser that allows you to refuse the setting of all or some cookies. Your browser’s ‘Help’ section will tell you how to do this. However, if you do use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of dcn.scot.</p>
                                <p>You can also visit the <a href="https://www.aboutcookies.org" target="_blank">AboutCookies</a> for more information about cookies and how to manage them.</p>
                                <p>We only use cookies that are essential to ensure that our website operates correctly. They help you move around the secure areas of the site, remember that you’ve signed in and let you access the services you have enrolled for without having to sign in again. </p>
                                <p>We don’t use any other kind of cookie. The table below shows you the cookies that we use:</p>
                                <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Service</Table.HeaderCell>
        <Table.HeaderCell>Cookie Name</Table.HeaderCell>
        <Table.HeaderCell>Expiry Period</Table.HeaderCell>
        <Table.HeaderCell>Purpose</Table.HeaderCell>
        <Table.HeaderCell>Cookie Type</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>dcn.scot</Table.Cell>
        <Table.Cell>EXPIRES_IN</Table.Cell>
        <Table.Cell>Session</Table.Cell>
        <Table.Cell>Manages the expiry duration in seconds from login</Table.Cell>
        <Table.Cell>Essential</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>dcn.scot</Table.Cell>
        <Table.Cell>TOKEN_TYPE</Table.Cell>
        <Table.Cell>Session</Table.Cell>
        <Table.Cell>Informs the website of the type of token generated when you logged in</Table.Cell>
        <Table.Cell>Essential</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>dcn.scot</Table.Cell>
        <Table.Cell>SCOPE</Table.Cell>
        <Table.Cell>Session</Table.Cell>
        <Table.Cell>Used to manage the session scope</Table.Cell>
        <Table.Cell>Essential</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>dcn.scot</Table.Cell>
        <Table.Cell>ID_TOKEN</Table.Cell>
        <Table.Cell>Session</Table.Cell>
        <Table.Cell>Used to manage the current session</Table.Cell>
        <Table.Cell>Essential</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>dcn.scot</Table.Cell>
        <Table.Cell>REFRESH_TOKEN</Table.Cell>
        <Table.Cell>Session</Table.Cell>
        <Table.Cell>Used to manage the current session</Table.Cell>
        <Table.Cell>Essential</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>dcn.scot</Table.Cell>
        <Table.Cell>ACCESS_TOKEN</Table.Cell>
        <Table.Cell>Session</Table.Cell>
        <Table.Cell>Used to manage the current session</Table.Cell>
        <Table.Cell>Essential</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>dcn.scot</Table.Cell>
        <Table.Cell>opbs</Table.Cell>
        <Table.Cell>Session</Table.Cell>
        <Table.Cell>Used to manage the current session</Table.Cell>
        <Table.Cell>Essential</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>dcn.scot</Table.Cell>
        <Table.Cell>commonAuthId</Table.Cell>
        <Table.Cell>Session</Table.Cell>
        <Table.Cell>Used to manage the current session</Table.Cell>
        <Table.Cell>Essential</Table.Cell>
      </Table.Row>
      </Table.Body>
      </Table>
      </div>
                            </Segment>

                        </Grid.Column>
                    </Grid>

                </Segment>

                <Footer />
            </>
        )
    }
}

export default withTranslation()(withRouter(CookiePolicy))

