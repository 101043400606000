import moment from "moment";
import ajax from "../../commons/agent.js";
import utility from "../../commons/utility.js";

let context;
let t;

const initialize = (self, translate) => {
  context = self;
  t = translate;
};

const populateVo = () => {
  let Vo = {
    organizationId: context.state.organizationId,
    organizationName: context.state.organizationName.trim(),
    website: context.state.website.trim(),
    organizationProfile: context.state.organizationProfile.trim(),
    organizationCategory: context.state.organizationCategory,
    country: context.state.country,
    stateProvinceRegion: context.state.state.trim(),
    city: context.state.city.trim(),
    zipCode: context.state.zipCode.trim(),
    addressLine1: context.state.addressLine1.trim(),
    addressLine2: context.state.addressLine2.trim(),
    emailId: context.state.corporateEmailId.trim(),
    contactNumber: context.state.corporatePhoneNo.trim(),
    // organizationUsersVo: context.state.organizationUsers
    organizationUsersVo: null,
  };
  return Vo;
};

const validateOrganizationName = () => {
  let error = "";
  const value = context.state.organizationName;
  if (!value) error = t("requiredValidationRule", { what: t("organizationName") });
  else if (value.length < 3) error = "Length should be more than 3 characters";
  else if (value.length > 50) error = "Length should be less than 50 characters";
  else {
    ajax.OrganizationMaster.findOrgNameAlreadyExists(value).end((err, response) => {
      utility.handleErrors(err);
      if (!err && response) {
        response.body &&
          context.setState((prevState) => ({
            error: {
              ...prevState.error,
              organizationName: "Organisation is already registered",
            },
          }));
      }
    });
  }
  context.setState((prevState) => ({
    error: { ...prevState.error, organizationName: error },
  }));
  return error;
};

// const validateOrganizationProfile = () => {
//     let error = ''
//     const value = context.state.organizationProfile
//     if (!value) error = t('requiredValidationRule', { what: t('organizationProfile') })
//     else if (value.length < 5) error = 'Length should be more than 5 characters'
//     else if (value.length > 2000) error = 'Length should be less than 2000 characters'

//     context.setState(prevState => ({ error: ({ ...prevState.error, organizationProfile: error }) }))
//     return error
// }

const validateWebsite = () => {
  let error = "";
  const value = context.state.website;
  if (!value) error = t("requiredValidationRule", { what: t("website") });
  if (value && !utility.isUrlValid(value)) error = "Enter a valid URL. eg: https://www.example.com";

  context.setState((prevState) => ({
    error: { ...prevState.error, website: error },
  }));
  return error;
};

const validateOrganizationCategory = () => {
  let error = "";
  const value = context.state.organizationCategory;
  if (!value) error = t("requiredValidationRule", { what: t("organizationCategory") });

  context.setState((prevState) => ({
    error: { ...prevState.error, organizationCategory: error },
  }));
  return error;
};

const validateAddress = () => {
  let error = "";
  const value = context.state.addressLine1;
  if (!value) error = t("requiredValidationRule", { what: t("addressLine1") });

  context.setState((prevState) => ({
    error: { ...prevState.error, addressLine1: error },
  }));
  return error;
};

const validateCity = () => {
  let error = "";
  const value = context.state.city;
  if (!value) error = t("requiredValidationRule", { what: t("city/town") });

  context.setState((prevState) => ({
    error: { ...prevState.error, city: error },
  }));
  return error;
};

// const validateState = () => {
//     let error = ''
//     const value = context.state.state
//     if (!value) error = t('requiredValidationRule', { what: t('state') })

//     context.setState(prevState => ({ error: ({ ...prevState.error, state: error }) }))
//     return error
// }

const validatePostalCode = () => {
  let error = "";
  const value = context.state.zipCode;
  if (!value) error = t("requiredValidationRule", { what: t("postCode") });

  context.setState((prevState) => ({
    error: { ...prevState.error, postalCode: error },
  }));
  return error;
};

// const validateCountry = () => {
//     let error = ''
//     const value = context.state.country
//     if (!value) error = t('requiredValidationRule', { what: t('country') })

//     context.setState(prevState => ({ error: ({ ...prevState.error, country: error }) }))
//     return error
// }

const validateCorporateEmailId = () => {
  let error = "";
  const value = context.state.corporateEmailId;
  if (!value) error = t("requiredValidationRule", { what: t("corporateEmailId") });
  if (value && !utility.isEmailValid(value)) error = "Enter a valid email. eg: example@mail.com";

  context.setState((prevState) => ({
    error: { ...prevState.error, corporateEmailId: error },
  }));
  return error;
};

// const validateCorporatePhoneNo = () => {
//     let error = ''
//     const value = context.state.corporatePhoneNo
//     if (!value) error = t('requiredValidationRule', { what: t('corporatePhoneNo') })
//     else if (value.search(/^[0-9]*$/) < 0) error = 'Must contain only numbers 0-9'

//     context.setState(prevState => ({ error: ({ ...prevState.error, corporatePhoneNo: error }) }))
//     return error
// }

const validateUserId = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].userId;
  if (!value) error = t("requiredValidationRule", { what: t("userId") });
  else if (!/^[a-zA-Z0-9][a-zA-Z0-9_.-]+$/.test(value)) error = "Username should not include (@ £ $ % ^ & \" ' ! ~ #)";
  else if (value.length < 5) error = "Length should be more than 5 characters";
  else if (value.length > 50) error = "Length should be less than 50 characters";

  if (error === "") {
    ajax.OrganizationMaster.findUserAlreadyExists(value).end((err, response) => {
      utility.handleErrors(err);
      if (!err && response) {
        if (response.body) {
          let temp = [...context.state.organizationUsers];
          temp[key].userIdError = "User ID already has been taken";
          context.setState({ organizationUsers: temp });
        }
      }
    });
  }
  let temp = [...context.state.organizationUsers];
  temp[key].userIdError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

const validateFirstName = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].firstName;
  if (!value) error = t("requiredValidationRule", { what: t("firstName") });

  let temp = [...context.state.organizationUsers];
  temp[key].firstNameError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

const validateLastName = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].lastName;
  if (!value) error = t("requiredValidationRule", { what: t("lastName") });

  let temp = [...context.state.organizationUsers];
  temp[key].lastNameError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

const validateContactNumber = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].contactNumber;
  if (!value) error = t("requiredValidationRule", { what: t("contactNumber") });
  else if (value.search(/^[0-9]*$/) < 0) error = "Must contain only numbers 0-9";

  let temp = [...context.state.organizationUsers];
  temp[key].contactNumberError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

const validateEmail = (key) => {
  let error = "";
  const value = context.state.organizationUsers[key].email;
  if (!value) error = t("requiredValidationRule", { what: t("email") });
  else if (value && !utility.isEmailValid(value)) error = "Enter a valid email. eg: example@mail.com";

  let temp = [...context.state.organizationUsers];
  temp[key].emailError = error;
  context.setState({ organizationUsers: temp });
  return error;
};

// const validatePassword = (key) => {
//     let error = ''
//     const value = context.state.organizationUsers[key].password
// if (!value) error = 'Password is required'
// else if (value.length < 12) error = 'Password must be at least 12 characters'
// else if (value.length > 29) error = 'Password must be less than 30 characters'
// else if (value.search(/[a-z]/) < 0) error = 'Password should have at least one lower case (a-z)'
// else if (value.search(/[0-9]/) < 0) error = 'Password should have at least one number (0-9)'
// else if (!((/[$&\(\)*,@\[\]^_{}~£]/).test(value))) error = 'Password should have at least one special character out of these acceptable characters ($ & ( ) * , @ [ ] ^ _ { } ~ £ )'
// else if (((/[!"%+]/).test(value))) error = 'Password should not include (! " % or +)'
// else if ((/(\r\n|\n|\r|\s)+/).test(value)) error = 'Password should not contain spaces or new line characters'

//     let temp = [...context.state.organizationUsers]
//     temp[key].passwordError = error
//     context.setState({ organizationUsers: temp })
//     return error
// }

// const validateConfirmPassword = (key) => {
//     let error = ''
//     const value = context.state.organizationUsers[key].confirmPassword
//     const passwordValue = context.state.organizationUsers[key].password
//     if (!value) error = t('requiredValidationRule', { what: t('password') })
//     if (passwordValue && value !== passwordValue) error = 'Password does not match'

//     let temp = [...context.state.organizationUsers]
//     temp[key].confirmPasswordError = error
//     context.setState({ organizationUsers: temp })
//     return error
// }

// const validateTermOfService = () => {
//     let error = ''
//     const value = context.state.isTermOfServiceChecked
//     if (!value) error = t('youMustAgree', { what: t('termOfService') })

//     context.setState(prevState => ({ error: ({ ...prevState.error, termOfService: error }) }))
//     return error
// }

// const validatePrivacyPolicy = () => {
//     let error = ''
//     const value = context.state.isPrivacyPolicyChecked
//     if (!value) error = t('youMustAgree', { what: t('privacyPolicy') })

//     context.setState(prevState => ({ error: ({ ...prevState.error, privacyPolicy: error }) }))
//     return error
// }

const validateAllUser = () => {
  let usersArray = [...context.state.organizationUsers];
  let eArray = [];
  usersArray.map((item, key) => {
    let e1 = validateUserId(key);
    let e2 = validateFirstName(key);
    let e3 = validateLastName(key);
    let e4 = validateContactNumber(key);
    let e5 = validateEmail(key);
    // let e6 = validatePassword(key)
    // let e7 = validateConfirmPassword(key)

    eArray.push(
      !!(
        (e1 || e2 || e3 || e4 || e5)
        // || e6 || e7
      )
    );
  });
  return eArray.includes(true);
};

const validateAll = (Vo) => {
  validateOrganizationName();
  // validateOrganizationProfile()
  validateWebsite();
  validateOrganizationCategory();
  validateAddress();
  validateCity();
  // validateState()
  validatePostalCode();
  // validateCountry()
  validateCorporateEmailId();
  // validateCorporatePhoneNo()
  // validateAllUser()

  return (
    validateOrganizationName() || validateWebsite() || validateOrganizationCategory() || validateAddress() || validateCity() || validatePostalCode() || validateCorporateEmailId()
    // validateAllUser()
  );
};
const validateUpdate = (Vo) => {
  // validateOrganizationName();
  // validateOrganizationProfile()
  validateWebsite();
  // validateOrganizationCategory();
  validateAddress();
  validateCity();
  // validateState()
  validatePostalCode();
  // validateCountry()
  validateCorporateEmailId();
  // validateCorporatePhoneNo()
  // validateAllUser()

  return (
    // validateOrganizationName() ||
    validateWebsite() ||
    // validateOrganizationCategory() ||
    validateAddress() ||
    validateCity() ||
    validatePostalCode() ||
    validateCorporateEmailId()
    // validateAllUser()
  );
};

const publish = () => {
  let Vo = populateVo();
  if (validateAll(Vo)) {
    utility.loadDisplayMessage(context, "error", "Invalid Input", ["Fill the required form fields with valid data."]);
  } else {
    // console.log(Vo);
    context.setState({ isSubmitting: true });
    ajax.OrganizationMaster.create(Vo).end((err, response) => {
      context.setState({ isSubmitting: false });
      utility.handleErrors(err);
      utility.loadingModalLoader(context, "Please wait. redirecting...");
      if (!err && response) {
        // console.log(response.body)
        // context.props.history.push({
        //   pathname: "/dashboard/orgrequest",
        //   state: {
        //     ...response.body,
        //   },
        // });
        context.props.reloadOrgContext();
        context.props.history.push(`/dashboard/organisations/${response.body.organizationBpid}`);
      } else {
        utility.unLoadingModalLoader(context);
        utility.loadDisplayMessage(context, "error", "Failed.", ["Problem initiating Workflow.", "Please contact system administrator."]);
      }
    });
  }
};
const update = () => {
  let Vo = populateVo();
  if (validateUpdate(Vo)) {
    utility.loadDisplayMessage(context, "error", "Invalid Input", ["Fill the required form fields with valid data."]);
  } else {
    // console.log(Vo);
    context.setState({ isSubmitting: true });
    ajax.OrganizationMaster.updateOrg(Vo).end((err, response) => {
      context.setState({ isSubmitting: false });
      utility.handleErrors(err);
      utility.loadingModalLoader(context, "Please wait. redirecting...");
      if (!err && response) {
        // console.log(response.body)
        // context.props.history.push({
        //   pathname: "/dashboard/orgrequest",
        //   state: {
        //     ...response.body,
        //   },
        // });
        context.props.reloadOrgContext();
        context.props.history.push(`/dashboard/organisations/${response.body.organizationBpid}`);
      } else {
        utility.unLoadingModalLoader(context);
        utility.loadDisplayMessage(context, "error", "Failed.", ["Problem initiating Workflow.", "Please contact system administrator."]);
      }
    });
  }
};
export default {
  initialize,
  publish,
  update,
  validateOrganizationName,
  // validateOrganizationProfile,
  validateWebsite,
  validateOrganizationCategory,
  validateAddress,
  validateCity,
  // validateState,
  validatePostalCode,
  // validateCountry,
  validateCorporateEmailId,
  // validateCorporatePhoneNo,
  validateUserId,
  validateFirstName,
  validateLastName,
  validateContactNumber,
  validateEmail,
  // validatePassword,
  // validateConfirmPassword,
  validateUserId,
  validateFirstName,
  validateLastName,
  validateContactNumber,
  validateEmail,
  // validatePassword,
  // validateConfirmPassword,
};
