import React, { Component } from "react";
import { Grid, Icon, Segment, Button, Label, Divider, Form, Checkbox, Table } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import ajax from '../../commons/agent.js'
import PageHeader from "../PageHeader.jsx";
import { withTranslation } from "react-i18next";
import utility from "../../commons/utility.js";
import MenuHeader from '../navbar.jsx';
import DisplayMessage from "../../commons/displayMessage.jsx";
import Footer from '../footer.jsx';
import { isValidSession } from "../Login/actions/session";
import dummyData from './TermsAndConditions.json'

export class TermsAndConditions extends Component {
	constructor(props) {
		super(props)

		this.state = {
			messageType: '', messageHeader: '', message: [],
			hasAcceptedLatest: true,
			accepted: false,
			data: null,
			// data: dummyData,

		}
	}
	componentDidMount() {
		this.onLoad()
	}


	onLoad = () => {
		let self = this		
		self.setState({ isFetchingData: true });
		ajax.OrganizationMaster.fetchTerms().end((err, response) => {
			self.setState({ isFetchingData: false });
			utility.loadingModalLoader(self, "Please wait. redirecting...");
			if (!err && response) {
				let data = JSON.parse(response.body.text)
				self.setState({ data });
			} else {
				utility.loadDisplayMessage(self, "error", "Something Went Wrong.", ["Please contact system administrator."]);
			}
		})
	}
	acceptTerms = () => {
		let self = this
		self.setState({ isAcceptingTermsAndPolicy: true });
		ajax.OrganizationMaster.acceptTerms().end((err, response) => {
			self.setState({ isAcceptingTermsAndPolicy: false });
			utility.loadingModalLoader(self, "Please wait. redirecting...");
			if (!err && response) {
				self.props.onLoad()
			} else {
				utility.loadDisplayMessage(self, "error", "Something Went Wrong.", ["Please contact system administrator."]);
			}
		})
	}

	render() {
		const { t, fromModal } = this.props
		return (
			<>
				{!fromModal && <Grid>
					<Grid.Column width={16} className="pb-0">
						<MenuHeader />
					</Grid.Column>
				</Grid>}

				<Segment basic className=''>
					{!fromModal && <PageHeader />}
					<DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />

					<Grid centered>
						<Grid.Column computer={fromModal ? 16 : 12} tablet={14} mobile={16} className='mx-1'>
							<Segment basic={fromModal} className='' loading={this.state.isFetchingData}>
								{this.state.data && <>
									<div className='textBig colPrimary textBold'>
										{this.state.data.title}
										{/* <span className='colGrey60 textSmall'> {this.state.data.version}  {this.state.data.date}</span> */}
									</div>
									<div className='colGrey60 textNormal'>Version {this.state.data.version},  {this.state.data.date}</div>

									{this.state.data.content && this.state.data.content.map(item => {
										return (
											<div>
												{item.titleBig && <div className="textBig textBold mt-1">{item.titleBig}</div>}
												{item.title && <div className="textNormal textBold mt-1">{item.title}</div>}

												{item.content && item.content.map(content => <div className="textNormal my-5px">{content}</div>)}
												{item.contentPl1 && item.contentPl1.map(contentPl1 => <div className="textNormal my-5px pl-1">{contentPl1}</div>)}

												{item.table &&
													<Table celled size='small' compact>
														{item.table.header &&
															<Table.Header>
																<Table.Row>
																	{item.table.header.map(item => <Table.HeaderCell>{item}</Table.HeaderCell>)}
																</Table.Row>
															</Table.Header>
														}
														{item.table.body &&
															<Table.Body>
																{item.table.body.map(row => <Table.Row verticalAlign='top'>{row.map(cell => <Table.Cell className='textNormal'>{cell}</Table.Cell>)}</Table.Row>)}
															</Table.Body>

														}
													</Table>

												}
											</div>
										)
									})}


									{/* {(isValidSession() && !fromModal && !hasAcceptedLatest) && <>
										<Divider />
										<Form>
											<Form.Field required>
												<Checkbox checked={this.state.accepted} label={`I have read and agreed to the ${this.state.data.title}`} onChange={() => this.setState(prev => ({ accepted: !prev.accepted }))} />
											</Form.Field>
										</Form>
										<Grid>
											<Grid.Column computer={16} textAlign='right' className='mt-1'>
												<Button size='small' disabled={!this.state.accepted} className='customBtn1Primary mr-0' onClick={() => this.acceptTerms()} loading={this.state.isAcceptingTermsAndPolicy}>Submit</Button>
												<div className="textSmaller"><i>{`By clicking submit you are accepting the ${this.state.data.title}`}</i></div>
											</Grid.Column>
										</Grid>

									</>} */}

								</>}
							</Segment>

						</Grid.Column>
					</Grid>

				</Segment>

				{!fromModal && <Footer />}
			</>
		)
	}
}

export default withTranslation()(withRouter(TermsAndConditions))

