import React, { Component } from 'react'
import { Grid, Input, Accordion, Dropdown, Checkbox, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message } from 'semantic-ui-react'
import PageHeader from '../PageHeader.jsx'
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import util from './OrganizationSignUp.js';
import DatePicker from "react-datepicker";
import DisplayMessage from '../../commons/displayMessage.jsx'
import Footer from '../footer.jsx';
import MenuHeader from '../navbar.jsx';
import { TermOfServiceModal } from './TermOfServiceModal.jsx';
import { PrivacyPolicyModal } from './PrivacyPolicyModal.jsx';

export class OrganizationSignUp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messageType: '', messageHeader: '', message: [],

			organizationCategoryOptions: [],
			countryOptions: [],
			organizationName: '',
			website: '',
			organizationProfile: '',
			organizationCategory: '',
			country: '',
			state: '',
			city: '',
			zipCode: '',
			addressLine1: '',
			addressLine2: '',
			corporateEmailId: '',
			corporatePhoneNo: '',

			isFetchingOrgcategoryOptions: false,
			isFetchingCountryOptions: false,

			isTermOfServiceChecked: false,
			isPrivacyPolicyChecked: false,
			termOfServiceIsOpen: false,
			privacyPolicyIsOpen: false,

			organizationUser: {
				userId: '',
				firstName: '',
				lastName: '',
				contactNumber: '',
				email: '',
				// password: '',
				userIdError: ''
			},
			organizationUsers: [],

			organizationUsersError: '',
			error: {},
		};

	}

	componentDidMount() {
		this.onLoad()
		util.initialize(this, this.props.t)
	}

	onLoad = () => {
		this.addOrganizationUser()

		this.setState({ isFetchingOrgcategoryOptions: true })
		ajax.OrganizationMaster.listCategories().end((err, res) => {
			this.setState({ isFetchingOrgcategoryOptions: false })
			if (!err && res) {
				this.setState({
					organizationCategoryOptions: res.body.map(item => ({ key: item.orgCategoryName, text: item.orgCategoryName, value: item.orgCategoryName })),
				})
			}
		})

		// this.setState({ isFetchingCountryOptions: true })
		// ajax.OrganizationMaster.listCountris().end((err, res) => {
		//     this.setState({ isFetchingCountryOptions: false })
		//     if (!err && res) {
		//         this.setState({
		//             countryOptions: res.body.map(item => ({ key: item.countryName, text: item.countryName, value: item.countryName })),
		//         })
		//     }
		// })
		// let myOrg = this.props.fetchOrganization(utility.fetchUserBpid())
		// this.setState({
		//     companyName: myOrg.organizationName,
		//     companyWebsite: myOrg.website,
		//     companyDescription: myOrg.organizationProfile,
		// })

		if (this.props.updateMode) {
			let oldOrg = this.props.location.state
			this.setState({
				organizationId: oldOrg.organizationId || '',
				organizationName: oldOrg.organizationName || '',
				website: oldOrg.website || '',
				organizationProfile: oldOrg.organizationProfile || '',
				organizationCategory: oldOrg.organizationCategory || '',
				country: oldOrg.country || '',
				state: oldOrg.stateProvinceRegion || '',
				city: oldOrg.city || '',
				zipCode: oldOrg.zipCode || '',
				addressLine1: oldOrg.addressLine1 || '',
				addressLine2: oldOrg.addressLine2 || '',
				corporateEmailId: oldOrg.emailId || '',
				// corporatePhoneNo: oldOrg.corporatePhoneNo,
			})
		}		

	}


	handleTextChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}
	handleRadioChange = (name, value) => {
		this.setState({ [name]: value })
	}
	handleDropdownChange = (name, value) => {
		this.setState({ [name]: value })
	}
	handleUsersIdTextChange = (e, key) => {
		let organizationUsers = [...this.state.organizationUsers]
		organizationUsers[key].userId = e.target.value
		this.setState({ organizationUsers: [...organizationUsers] })
	}
	handleFirstNameTextChange = (e, key) => {
		let organizationUsers = [...this.state.organizationUsers]
		organizationUsers[key].firstName = e.target.value
		this.setState({ organizationUsers: [...organizationUsers] })
	}
	handleLastNameTextChange = (e, key) => {
		let organizationUsers = [...this.state.organizationUsers]
		organizationUsers[key].lastName = e.target.value
		this.setState({ organizationUsers: [...organizationUsers] })
	}
	handleContactNumberTextChange = (e, key) => {
		let organizationUsers = [...this.state.organizationUsers]
		organizationUsers[key].contactNumber = e.target.value
		this.setState({ organizationUsers: [...organizationUsers] })
	}
	handleEmailTextChange = (e, key) => {
		let organizationUsers = [...this.state.organizationUsers]
		organizationUsers[key].email = e.target.value
		this.setState({ organizationUsers: [...organizationUsers] })
	}
	// handlePasswordTextChange = (e, key) => {
	//     let organizationUsers = [...this.state.organizationUsers]
	//     organizationUsers[key].password = e.target.value
	//     this.setState({ organizationUsers: [...organizationUsers] })
	// }
	// handleConfirmPasswordTextChange = (e, key) => {
	//     let organizationUsers = [...this.state.organizationUsers]
	//     organizationUsers[key].confirmPassword = e.target.value
	//     this.setState({ organizationUsers: [...organizationUsers] })
	// }
	addOrganizationUser = () => {
		if (this.state.organizationUsers.length < 5) {
			this.setState({ organizationUsers: [...this.state.organizationUsers, { ...this.state.organizationUser }] })
		}
		else {
			this.setState({ organizationUsersError: 'Cannot add more than 5 users' })
		}
	}
	removeOrganizationUser = (key) => {
		let organizationUsers = [...this.state.organizationUsers.filter((item, k) => k !== key)]
		this.setState({ organizationUsers: [...organizationUsers] })
	}

	render() {
		const { t, updateMode } = this.props
		const { activeIndex } = this.state

		return (
			<>
				<PageHeader />
				
				<Segment basic className='pt-0 px-1 my-0'>
					<DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
					<Grid columns='equal' centered>
						<Grid.Column computer={9} tablet={16} mobile={16}>
							<Segment className='noShadow'>
								<Form size='small'>
									<Form.Input readOnly={updateMode} name='organizationName' value={this.state.organizationName} onChange={(e) => this.handleTextChange(e)} fluid label={t('organizationName')} placeholder={t('organizationName')} onBlur={() => util.validateOrganizationName()} required />
									{this.state.error.organizationName ? <div className='errorText'>{this.state.error.organizationName}</div> : <div className='validationInfoText'>{t('characterLimitValidationRule', { from: '3', to: '50' })}</div>}
									{/* <Form.TextArea rows={2} name='organizationProfile' value={this.state.organizationProfile} onChange={(e) => this.handleTextChange(e)} label={t('organizationProfile')} placeholder={t('organizationProfile')} onBlur={() => util.validateOrganizationProfile()} required />
                                    {this.state.error.organizationProfile ? <div className='errorText'>{this.state.error.organizationProfile}</div> : <div className='validationInfoText'>{t('characterLimitValidationRule', { from: '5', to: '2000' })}</div>} */}
									<Form.Group widths='equal'>
										<Form.Field>
											<Form.Input name='website' value={this.state.website} onChange={(e) => this.handleTextChange(e)} fluid label={t('website')} placeholder={t('website')} onBlur={() => util.validateWebsite()} required />
											{this.state.error.website && <div className='errorText' width='50%'>{this.state.error.website}</div>}
										</Form.Field>
										<Form.Field>
											{updateMode ?
												<Form.Input readOnly name='organizationCategory' value={this.state.organizationCategory} fluid label={t('organizationCategory')} required />
												:
												<>
													<Form.Select
														selection fluid required search loading={this.state.isFetchingOrgcategoryOptions}
														label={t('organizationCategory')} placeholder={t('organizationCategory')}
														options={this.state.organizationCategoryOptions} value={this.state.organizationCategory}
														onChange={(e, { value }) => this.handleDropdownChange('organizationCategory', value)}
														onBlur={() => util.validateOrganizationCategory()}
													/>
													{this.state.error.organizationCategory && <div className='errorText' width='50%'>{this.state.error.organizationCategory}</div>}
												</>
											}
										</Form.Field>
									</Form.Group>
									<Form.TextArea rows={1} name='addressLine1' value={this.state.addressLine1} onChange={(e) => this.handleTextChange(e)} label={t('addressLine1')} placeholder={t('addressLine1')} onBlur={() => util.validateAddress()} required />
									{this.state.error.addressLine1 && <div className='errorText' width='50%'>{this.state.error.addressLine1}</div>}
									<Form.TextArea rows={1} name='addressLine2' value={this.state.addressLine2} onChange={(e) => this.handleTextChange(e)} label={t('addressLine2')} placeholder={t('addressLine2')} />
									<Form.Group widths='equal'>
										<Form.Field>
											<Form.Input name='city' value={this.state.city} onChange={(e) => this.handleTextChange(e)} fluid label={t('city/town')} placeholder={t('city/town')} onBlur={() => util.validateCity()} required />
											{this.state.error.city && <div className='errorText' width='50%'>{this.state.error.city}</div>}
										</Form.Field>
										{/* <Form.Field>
                                            <Form.Input name='state' value={this.state.state} onChange={(e) => this.handleTextChange(e)} fluid label={t('state')} placeholder={t('state')} onBlur={() => util.validateState()} required />
                                            {this.state.error.state && <div className='errorText' width='50%'>{this.state.error.state}</div>}
                                        </Form.Field> */}
									</Form.Group>
									<Form.Group widths='equal'>
										<Form.Field>
											<Form.Input name='zipCode' value={this.state.zipCode} onChange={(e) => this.handleTextChange(e)} fluid label={t('postCode')} placeholder={t('postCode')} onBlur={() => util.validatePostalCode()} required />
											{this.state.error.postalCode && <div className='errorText' width='50%'>{this.state.error.postalCode}</div>}
										</Form.Field>
										<Form.Field>
											<Form.Input name='corporateEmailId' value={this.state.corporateEmailId} onChange={(e) => this.handleTextChange(e)} fluid label={t('corporateEmailId')} placeholder={t('corporateEmailId')} onBlur={() => util.validateCorporateEmailId()} required />
											{this.state.error.corporateEmailId && <div className='errorText' width='50%'>{this.state.error.corporateEmailId}</div>}
										</Form.Field>

									</Form.Group>
								</Form>
								<Grid>
									<Grid.Column computer={16} textAlign='right'>
										{updateMode ?
											<Button className='customBtn1Primary mr-0 mt-1' onClick={() => util.update()} >{t('save')}</Button>
											:
											<Button className='customBtn1Primary mr-0 mt-1' onClick={() => util.publish()} >{t('register')}</Button>
										}
									</Grid.Column>
								</Grid>
							</Segment>
						</Grid.Column>
						{/* <Grid.Column>
                            <Grid.Column computer={16} className='pb-0' textAlign='center'>
                                <div className='textNormal colPrimary textBold'>{t('pointOfContact(s)')}</div>
                            </Grid.Column>

                            {this.state.organizationUsers && this.state.organizationUsers.map((item, key) =>
                                <Segment className='noShadow'>
                                    {(key > 0) && <Icon style={{ position: 'absolute', top: '0', right: '2px', zIndex: '1' }} link name='close' onClick={() => this.removeOrganizationUser(key)} />}

                                    < Form size='small' >
                                        <Form.Group widths='equal'>
                                            <Form.Field width={2}><Icon name='user' size='big' circular className='mt-5px colPrimary' /></Form.Field>
                                            <Form.Field width={14}>
                                                <Form.Input fluid value={item.userId} onChange={(e) => this.handleUsersIdTextChange(e, key)} fluid label={t('userId')} placeholder={t('userId')} onBlur={() => util.validateUserId(key)} required />
                                                {item.userIdError && <div className='errorText'>{item.userIdError}</div>}
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <Form.Input value={item.firstName} onChange={(e) => this.handleFirstNameTextChange(e, key)} fluid label={t('firstName')} placeholder={t('firstName')} onBlur={() => util.validateFirstName(key)} required />
                                                {item.firstNameError && <div className='errorText'>{item.firstNameError}</div>}
                                            </Form.Field>
                                            <Form.Field>
                                                <Form.Input value={item.lastName} onChange={(e) => this.handleLastNameTextChange(e, key)} fluid label={t('lastName')} placeholder={t('lastName')} onBlur={() => util.validateLastName(key)} required />
                                                {item.lastNameError && <div className='errorText'>{item.lastNameError}</div>}
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <Form.Input value={item.contactNumber} onChange={(e) => this.handleContactNumberTextChange(e, key)} fluid label={t('contactNumber')} placeholder={t('contactNumber')} onBlur={() => util.validateContactNumber(key)} required />
                                                {item.contactNumberError && <div className='errorText'>{item.contactNumberError}</div>}
                                            </Form.Field>
                                            <Form.Field>
                                                <Form.Input value={item.email} onChange={(e) => this.handleEmailTextChange(e, key)} fluid label={t('email')} placeholder={t('email')} onBlur={() => util.validateEmail(key)} required />
                                                {item.emailError && <div className='errorText'>{item.emailError}</div>}
                                            </Form.Field>
                                        </Form.Group>
                                       
                                    </Form>
                                </Segment>
                            )
                            }
                            {this.state.organizationUsersError && <Message color='red'><p>{this.state.organizationUsersError}</p> </Message>}
                            <Grid className='my-5px'>
                                <Grid.Column computer={16} textAlign='center'>
                                    <Button className='customBtn1Secondary' onClick={() => this.addOrganizationUser()} ><Icon name='add' />{t('addMoreAdministrator(s)')}</Button>
                                </Grid.Column>
                            </Grid>                           
                            
                        </Grid.Column> */}

					</Grid>
				</Segment>


			</>
		)
	}
}

export default withTranslation()(withRouter(OrganizationSignUp))
